export default class ViewportMapBounds {
    constructor({ northeast = {}, southwest = {}, location = {} }) {
        this.lat = this._toNumber(location.lat);
        this.lon = this._toNumber(location.lon);
        this.nwLat = this._toNumber(northeast.lat);
        this.nwLng = this._toNumber(northeast.lng);
        this.seLat = this._toNumber(southwest.lat);
        this.seLng = this._toNumber(southwest.lng);
    }

    _toNumber(value) {
        return Number(value);
    }
}
